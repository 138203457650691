import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"

const PolitikaZaPoveritelnost = props => {
  return (
    <Layout>
      <SEO title={"Политика за поверителност"} />
      <div className="static-page-holder">
        <h1>ПОЛИТИКА ЗА ПОВЕРИТЕЛНОСТ И ЗАЩИТА НА ЛИЧНИТЕ ДАННИ</h1>
        <p>
          Чл. 1 / Информация относно дружеството, което обработва данните Ви:
        </p>

        <p>
          Special Security Group е търговското наименование на фирма Special
          Security Group ООД, регистрирано в Търговския регистър към Агенция по
          вписванията и притежава и управлява медийните сайтове:
          www.yambol-sot.netlify.app
        </p>
        <p>
          Спешъл Сикюрити Груп ООД (наричано по-долу за краткост „Администратор“
          или „Дружество“) осъществява дейността си в съответствие със Закона за
          защита на личните данни и Регламен (ЕС) 2016/679 на Европейския
          парламент и на Съвета от 27 април 2016 година относно защитата на
          физическите лица във връзка с обработването на лични данни и относно
          свободното движение на такива данни. Настоящият документ съдържа
          информация по отношение начина на обработване на личните данни, типа
          лични данни, които се събират, целта на използване на събраните лични
          данни, достъпа на трети лица до тези данни, мерките за сигурност,
          които се предприемат по отношение събраните личните данни, както и
          опциите, които притежавате във връзка с използването на предоставените
          от вас лични данни. Всички лични данни се събират и обработват в
          съответствие с действащото в България законодателство в областта на
          защита на личните данни. „Спешъл Сикюрити Груп ООД“ обработва лични
          данни при използване на някой от управляваните от дружеството уеб
          сайтове или в информационни бюлетини на място в центровете. Личните
          данни, които са обект на обработката от дружеството се предоставят
          директно от Вас или се събират автоматично. С настоящата Политиката за
          поверителност и защита на личните данни „Спешъл Сикюрити Груп“ ООД
          отчита неприкосновеността на личността и полага усилия за защита срещу
          неправомерно обработване на личните данни на физическите лица.
        </p>

        <h2>Терминология:</h2>

        <p>
          {" "}
          „Лични данни“ представлява всяка информация, чрез която физическо лице
          се идентифицира или може да бъде идентифицирано;
        </p>

        <p>
          „Субект на данни“ е физическо лице, което е идентифицирано или което
          може да бъде идентифицирано въз основа на определена информация;
        </p>

        <p>
          {" "}
          „Обработване“ означава всяка операция или съвкупност от операции,
          извършвана с лични данни или набор от лични данни чрез автоматични или
          други средства като събиране, записване, организиране, структуриране,
          съхранение, адаптиране или промяна, извличане, консултиране, употреба,
          разкриване чрез предаване, разпространяване или друг начин, по който
          данните стават достъпни, подреждане или комбиниране, ограничаване,
          изтриване или унищожаване;
        </p>

        <p>
          {" "}
          „Администратор“ означава физическо или юридическо лице, публичен
          орган, агенция или друга структура, която сама или съвместно с други
          определя целите и средствата за обработването на лични данни.
          Администратор на личните данни е „Спешъл Сикюрити Груп“ ООД;
        </p>

        <p>
          {" "}
          „Обработващ лични данни“ означава физическо или юридическо лице,
          публичен орган, агенция или друга структура, която обработва лични
          данни от името на администратора. Под „обработващ лични данни“ в
          настоящата политика се има предвид служител на „Спешъл Сикюрити Груп“
          ООД;
        </p>

        <p>
          {" "}
          Чл. 2 / Основание за събиране, обработване и съхраняване на Вашите
          лични данни:
        </p>

        <p>
          Администраторът събира и обработва Вашите лични данни във връзка с
          използването на управляваните медийни сайтове или други услуги на
          Дружеството на основание чл. 6, ал.1, Регламент (ЕС) 2016/679 (GDPR),
          и по-конкретно въз основа на следното основание:
        </p>
        <ul>
          <li>Изрично получено съгласие от Вас като клиент;</li>

          <li>
            Изпълнение на задълженията на Администратора по договор с Вас;
          </li>

          <li>
            Спазване на законово задължение, което се прилага спрямо
            Администратора;
          </li>

          <li>
            За целите на легитимните интереси на Администратора или на трета
            страна;
          </li>

          <li>
            Предприемане на стъпки по Ваше искане за сключване на договор, ако
            сте кандидат за работа.
          </li>

          <li>
            За изпълнение на договор или предприемане на стъпки за сключване на
            такъв с търговски партньор.
          </li>
        </ul>
        <p>
          Чл. 3 / Цели и принципи при събирането, обработването и съхраняването
          на Вашите лични данни:
        </p>

        <p>
          {" "}
          (1) Ние събираме и обработваме лични данни, които Вие ни предоставяте
          във връзка с използването на онлайн магазина или заявка за обратна
          връзка с Вас относно възползване от предоставяни от нас услуги,
          включително за следните цели:
        </p>
        <ul>
          <li>
            За измерване и проследяване на статистически зависимости за
            потребителското поведение в сайтовете на „Спешъл Сикюрити Груп“ ООД
            с цел подобряване на нашите продукти;
          </li>

          <li>
            {" "}
            За маркетингови цели, включително персонализирани реклами, които са
            свързани с търговската дейност на „Спешъл Сикюрити Груп“ ООД;
          </li>

          <li>
            За регистрация за предлагани от нас услуги – за да ползвате услуги,
            изискващи регистрация в нашите сайтове и комуникация с вас,
            включително, но не само при раздаване на награди в игри, за
            разрешаване на спорове и отстраняване на проблеми;
          </li>

          <li>
            Обезпечаване на изпълнението на договора за предоставяне на
            съответната услуга;
          </li>

          <li>
            Изпращане на информационен бюлетин при изразено от Вас желание;
          </li>

          <li> Разпознаване и събиране на IP адреса позволява:</li>
        </ul>

        <div>
          {" "}
          разкриване на идентичността на потребители в случаите, когато това е
          необходимо в изпълнение на закона, юридически процедури или за да
          спазят настоящите условия;
        </div>

        <div>
          {" "}
          анализ на трафика към сайтовете и предпазване от злонамерени атаки;
        </div>

        <div>
          {" "}
          да ви покажем реклама свързана с Вашата локация или сервизна
          информация
        </div>
        <ul>
          <li>Индивидуализация на страна от договора;</li>
          <li>Счетоводни цели;</li>
        </ul>

        <p>
          {" "}
          (2) Личните данни, които предоставяте директно, се обработват и
          използват за целите, посочени в настоящата Политика за поверителност и
          защита на личните данни. Предоставянето на личните ви данни при
          използването на сайтовете не е задължително, освен ако желаете да си
          направите доброволна регистрация.
        </p>

        <p>
          {" "}
          (3) Личните данни, които събираме при извършена от вас доброволна
          регистрация за възползване от предоставяна от нас услуга са:
        </p>
        <ul>
          <li>Име и фамилия;</li>
          <li> Местопребиваване ;</li>
          <li>Телефон за връзка с Вас;</li>
          <li> Имейл.</li>
        </ul>

        <p>
          {" "}
          Част от данните представляват задължителна информация, която следва да
          посочите, за да може успешно да се регистрирате и чрез която може да
          бъдете идентифициран лично, когато се свържем с Вас.
        </p>

        <p>
          {" "}
          Когато предоставяте публично лична информация в сайтовете ни, например
          чрез коментари, следва да имате предвид, че тя може да бъде видяна от
          други посетители на същите сайтове и ние нямаме възможност да
          предотвратим по-нататъшното й използване от тях.
        </p>

        <p>
          (4) Личните данни, които събираме и обработваме при издаване на
          документи за продажба:
        </p>

        <ul>
          {" "}
          <li>Три имена</li>
          <li> Местопребиваване</li>
          <li> ЕГН</li>
          <li>Номер на личен документ</li>
        </ul>

        <p>
          {" "}
          (5) Личните данни, които събираме и обработваме при извършване на
          услуга във физическите ни обекти:
        </p>
        <ul>
          <li> Име и фамилия</li>

          <li>Дата на раждане</li>

          <li>Биометрични данни</li>
        </ul>

        <p>
          {" "}
          (6) Ние спазване следните принципи при обработката на Вашите лични
          данни:
        </p>
        <ul>
          <li> Законосъобразност, добросъвестност и прозрачност</li>

          <li> Ограничение на целите за обработване</li>

          <li>
            {" "}
            Съотносимост с целите на обработка и свеждане до минимум на
            събраните данни
          </li>

          <li> Точност и актуалност на данните</li>

          <li>Ограничение на съхранението с оглед постигане на целите</li>

          <li>
            {" "}
            Цялостност и поверителност на обработването и гарантиране на
            подходящо ниво на сигурност на личните данни.
          </li>
        </ul>

        <p>
          (7) При обработването и съхранението на личните данни, Администраторът
          може да обработва и съхранява личните данни с цел защита следните си
          легитимни интереси:
        </p>

        <ul>
          <li>
            изпълнение на задълженията си към Национална агенция по приходите,
            Министерство на вътрешните работи и други държавни и общински
            органи.
          </li>
        </ul>

        <p> Чл. 4 / Как събираме и обработваме Вашите лични данни:</p>

        <p>
          {" "}
          Правното основание, въз основа на което „Спешъл Сикюрити Груп“ ООД
          обработва лични данни е даденото от ваша страна доброволно съгласие.
          Във връзка с целите, посочени по-долу, вие следва да дадете изрично
          съгласието си за всяка отделна цел посредством отбелязване в поле за
          отметка (CheckBox), което се появява като квадратна кутийка с
          придружаващ я текст. Когато е направен избор, се появява отметка в
          кутийката, с оглед на което вие вече сте дали съгласието си вашите
          лични данни да бъдат обработвани от „Спешъл Сикюрити Груп“ ООД. Когато
          не е направен избор, кутийката е празна. Имайте предвид, че има
          случаи, в които съгласието ви е задължителна предпоставка за
          регистрация в съответния сайт от групата на „Спешъл Сикюрити Груп“
          ООД.
        </p>

        <p> (1) Как използваме социалните медии:</p>

        <ul>
          <li>
            {" "}
            Използваме социалните медии по различни начини. От една страна ги
            използваме, за да промотираме нашата дейност, продукти и услуги,
            както и за да рекламираме, подобрим и администрираме своите
            собствени услуги.
          </li>
          <li>
            {" "}
            Интеграция с приставки на социални медии. Интегрирали сме приставки
            на социални медии в нашите сайтове. Това означава, че когато
            кликнете върху един от тези бутони (като бутона „харесва ми“ на
            Facebook), споделяме част от информацията ви с доставчика на
            съответната социална медия. Ако в този момент сте вписани в профила
            си в съответната социална медия, доставчикът на медията може да
            свърже тази информация с Вашия профил в социалната медия и
            евентуално да покаже вашите действия на профила Ви, правейки ги
            видими за останалите потребители, с които сте свързани.
          </li>
          <li>
            {" "}
            Може да интегрираме още услуги, свързани със социални медии (като
            съобщения в социални медии), чрез които да общувате с нас или с ваши
            познати във връзка с нашите услуги. Може също да поддържаме профили
            в социални медии и да предлагаме приложения на сайтовете на различни
            социални медии. Всеки път, когато се свържете с нас чрез социална
            медия, доставчикът на съответната социална медия може да ви позволи
            да споделяте информация с нас. Ако изберете да споделяте, ще бъдете
            общо уведомени от доставчика, коя информация ще бъде споделяна.
            Например, когато се вписвате в своя профил посредством профила си в
            социална медия, определена информация (както е било разрешено от
            доставчика на социалната медия) може да бъде споделена с нас. Това
            може да включва вашия адрес, възраст или профилни снимки, запазени
            във вашия профил.
          </li>
        </ul>

        <p> (2) Лични данни, които се събират автоматично:</p>

        <ul>
          <li>
            {" "}
            Когато посещавате някоя от уеб страниците ни, нашият уеб сървър
            автоматично разпознава и събира Вашия IP адрес, който е определен от
            Вашия доставчик на интернет услуги и не ви идентифицира лично.
          </li>

          <li>
            {" "}
            Обобщена информация – Log Files – както много други сайтове ние
            получаваме информация от log files (съвкупност от системна
            информация за потребителя): IP адрес; ISP (Internet Service
            Provider); браузъра, който използвате, когато посещавате сайт от
            групата (като Google Chrome, Internet Explorer и Mozilla Firefox );
            времето, което сте прекарали в даден сайт и кои страници в сайта сте
            посетили. Информацията, която се съдържа в тези файлове включва:
          </li>
          <li>
            {" "}
            Бисквитки („Cookie“): това е малко количество информация, която уеб
            сървърът изпраща на уеб браузъра, позволявайки на сървъра да събира
            обратна информация от браузъра. Вие можете да изберете да изтриете
            нашите или на трета страна бисквитки чрез опциите на всеки браузър.
            Това може да повлияе на взаимодействието с наш или друг сайт. Повече
            информация за „cookie“ можете да намерите на
            http://www.allaboutcookies.org/faqs/cookies.html
          </li>
        </ul>
        <p>Използваме следните типове „бисквитки“:</p>

        <p>
          {" "}
          (1) Бисквитки за статистики, които анонимно запомнят компютъра или
          мобилното ви устройство, когато посещавате нашите уебсайтове. Те
          следят начина на търсене и ни помагат да изградим профил за това как
          нашите читатели използват уебсайта. Можем да използваме тази
          информация, за да показваме реклами, които биха могли да ви
          представляват особен интерес на наши и други уебсайтове.
        </p>
        <p>
          (2) Бисквитки за услуга, които ни помагат да направим нашите уеб
          сайтове възможно най-ефективни. Те дават възможност да се запомни
          регистрацията и данните за вход, да се запазят предпочитаните ви
          настройки.
        </p>
        <p>
          {" "}
          (3) Рекламни и аналитични бисквитки на трети страни, които се поставят
          от името на независими рекламодатели, които рекламират на нашите
          сайтове. Тези „бисквитки“ могат да бъдат поставени в рекламата или на
          други места в нашите сайтове. Тези „бисквитки“ са анонимни – не могат
          да ви идентифицират. Те се използват за статистически анализ, като
          позволяват на рекламодателя да преброи колко хора са видели неговата
          реклама или са я видели повече от веднъж. Те също така могат да
          позволят на рекламодателя да приспособява рекламата към Вас, когато
          посещавате други уебсайтове.
        </p>
        <p>
          (4) Нямаме достъп до „бисквитки“ на трети страни, а организациите на
          трети страни нямат достъп до нашите. Организациите на трети страни,
          които поставят бисквитки, имат собствени строги правила за
          поверителност.
        </p>
        <p>
          {" "}
          Уеб указателите (Web beacons) са файлове, които позволяват на даден
          уеб сайт да събира информация за броя на потребителите, които са го
          посетили и да има достъп до техните „бисквитки“ („cookies“). Повече
          информация за уеб указателите можете да намерите на:
          http://www.allaboutcookies.org/faqs/beacons.html
        </p>
        <p>
          {" "}
          „Спешъл Сикюрити Груп“ ООД си запазва правото да позволява на компании
          като трета страна да показват реклами или набират информация за
          сайтове от групата като записват информация от бисквитки или уеб
          указатели от Вашия компютър.
        </p>
        <p> Чл.5 / Обработване на лични данни на лица под 16 години:</p>

        <p>
          {" "}
          Разбираме значението на това да се предприемат допълнителни предпазни
          мерки за защита на неприкосновеността и безопасността на децата.
          Съобразно с това, деца на възраст под 16 години нямат право да се
          регистрират в сайтовете на „Спешъл Сикюрити Груп“ ООД без изричното
          съгласие на свой родител/настойник. Ако сте на възраст под 16 години,
          моля, не изпращайте никаква информация за себе си, включително, но не
          само име, адрес, телефонен номер, имейл адрес и други. В случай, че
          научим, че сме събрали лична информация от дете на възраст под 16
          години без съгласието на родител/настойник, ще изтрием тази информация
          възможно най-бързо. Ако смятате, че може да имаме информация от или за
          дете под 16 години, моля, свържете се с нас на контактите, посочени
          по-долу.
        </p>
        <p>Чл. 6 / Какво правим, за да защитим личните ви данни:</p>

        <p>
          {" "}
          Ние полагаме сериозни усилия, за да гарантираме сигурността на нашите
          уебсайтове. Данните, които ни предоставяте, са защитени чрез
          технологията SSL (Слой със защитен сокет). SSL представлява стандартен
          метод в областта за кодиране на лични данни, така че те да могат да се
          прехвърлят сигурно по Интернет.
        </p>
        <p>
          {" "}
          Запазването на поверителността и сигурността на личната ви информация
          е с най-висок приоритет и ограничаваме достъпа до нея само на онези
          служители на „Спешъл Сикюрити Груп“ ООД, които трябва да влязат в
          контакт с нея, за да изпълнят своята роля и да дадат възможност на
          услугите ни да ви бъдат предоставени. Ние ще запазим Вашата информация
          поверителна, освен когато разкриването й се изисква по силата на закон
          или за технически цели.
        </p>
        <p>
          {" "}
          Ние полагаме максимално възможни усилия, за да защитим личната ви
          информация, но въпреки това, при споделяне на информация в интернет
          следва да имате предвид, че предаването на информация по интернет
          никога не може да бъде напълно сигурно, както и че сигурността не може
          да бъде напълно гарантирана.
        </p>
        <p>
          Чл. 7 / Вашите права и възможности във връзка с обработване на личните
          ви данни:
        </p>
        <p>
          Разполагате с определени права съгласно приложимото законодателство по
          отношение на личните данни, които съхраняваме за Вас, а именно:
        </p>
        <p>
          {" "}
          Оправомощени сте да изискате достъп и да получите информация по
          отношение личните данни, които се съхраняват за Вас, както и
          информация, касаеща целите на обработването, категориите лични данни,
          получателите, пред които е възможно да бъдат разкрити личните ви данни
          и друго;
        </p>
        <p>
          Имате право по всяко време да поискате коригиране на неточни данни,
          свързани с Вас, както и допълване на непълните данни, ако това е
          целесъобразно и/или необходимо с оглед целта, с която данните се
          обработват;
        </p>
        <p>
          {" "}
          По всяко време можете да оттеглите съгласието си за използване на
          личните ви данни, което сте предоставили в предходен момент. В този
          случай, оттеглянето на съгласието ви за използването или обработването
          на личните ви данни може да доведе до невъзможност да се възползвате
          от определени продукти или услуги, предоставяни от горепосочените
          сайтове;
        </p>
        <p>
          Ако прецените, че не желаете „Спешъл Сикюрити Груп“ ООД да обработва
          личните ви данни, вие имате право да бъдете „забравени“, т.е. можете
          по всяко време да поискате личните ви данни да бъдат изтрити при
          наличие на някое от следните основания:
        </p>
        <ul>
          <li>
            {" "}
            4.1. личните ви данни повече не са необходими за целите, за които са
            били събрани или обработвани по друг начин;
          </li>
          <li>
            {" "}
            4.2. в случай, че сте оттеглили съгласието си за обработване на
            личните ви данни;
          </li>
          <li> 4.3. ако личните ви данни се обработват незаконосъобразно;</li>
          <li>
            {" "}
            4.4. в случай, че сте възразили срещу обработването на личните ви
            данни;
          </li>
          <li>
            {" "}
            4.5. други случаи, предвидени в законодателството, уреждащо защитата
            на личните данни;
          </li>
        </ul>

        <p>
          {" "}
          В редица случаи, Вие имате право да поискате вместо изтриване на
          данните, ограничаване на обработването на личните ви данни;
        </p>
        <p>
          {" "}
          Право на възражение пред „Спешъл Сикюрити Груп“ ООД срещу
          обработването на личните ви данни при наличие на законово основание за
          това;
        </p>
        <p>
          {" "}
          Всички изброени права може да упражните чрез подаване на писмено
          заявление в свободна форма на следния електронен
          адрес:ssgroupbg@gmail.com , изпратено от регистрационния ви имейл
          адрес, съдържащо най-малко следното:
        </p>
        <ul>
          <li>
            Име, фамилия, имейл и други данни за идентифициране на съответното
            физическо лице;
          </li>
          <li> описание на искането;</li>
          <li> предпочитана форма за предоставяне на информацията;</li>
        </ul>

        <p>
          {" "}
          Подаването на заявлението е напълно безплатно. Срокът за разглеждане
          на заявлението е едномесечен, считано от датата на получаване на
          заявлението.
        </p>
        <p>
          {" "}
          Освен горепосочените права „Спешъл Сикюрити Груп“ ООД ви дава право да
          извършвате сами някои от следните промени във връзка с обработването
          на личните ви данни, а именно:
        </p>
        <p>
          {" "}
          Можете да управлявате предпочитанията си за абонаменти от настройките
          на потребителския си профил, както и да се откажете от получаването на
          конкретен e-mail бюлетин чрез избор на опцията за отписване в края на
          всеки получен e-mail.
        </p>
        <p> Полезна информация:</p>

        <p>
          {" "}
          Има редица полезни инструменти и ресурси, които можете да използвате,
          за да контролирате поверителността си онлайн, например чрез онлайн
          избора си или инициативата за мрежова реклама
          http://www.youronlinechoices.com/bg/.
        </p>
        <p>
          Особено препоръчваме да се запознаете с функциите и настройките за
          поверителност на браузъра, който използвате.
        </p>
        <p>
          На следния адрес можете да откриете много полезна информация за онлайн
          поведенческа реклама и защита на личните данни в Интернет
          http://www.youronlinechoices.com/bg/.
        </p>
        <p>
          {" "}
          За да научите повече как ние ползваме трета страна за събиране и
          използване на информация върху страниците от групата на „Спешъл
          Сикюрити Груп“ ООД моля, посетете следната страница:
        </p>
        <p> http://www.google.bg/privacypolicy.html</p>

        <p>
          {" "}
          Google, като трета страна в ролята на доставчик, използва „бисквитки“
          за показването на реклами на сайтовете на „Спешъл Сикюрити Груп“ ООД.
        </p>
        <p>
          {" "}
          С помощта на „бисквитката“ DART Google ви показва реклами въз основа
          на посещенията както в сайтовете на „Спешъл Сикюрити Груп“ ООД, така и
          на други сайтове в интернет.
        </p>
        <p>
          Вие можете да откажете използването на „бисквитката“ DART, като
          посетите страницата с Декларацията за поверителност за рекламната и
          съдържателната мрежа на Google.
        </p>
        <p>
          {" "}
          Повече информация за защита на лични данни, може да получите на сайта
          на Комисия за защита на личните данни:
          https://www.cpdp.bg/?p=element&aid=1115
        </p>
        <p> Връзка с нас:</p>

        <p>
          {" "}
          В случай че имате въпроси, коментари или оплаквания във връзка с
          настоящата Политика за поверителност и защита на личните данни или в
          случай че желаете да упражните правата си или да актуализирате
          предоставените лични данни, моля да се свържете с нас чрез email на
          следния адрес: ssgroupbg@gmail.com
        </p>
        <p>
          {" "}
          Можете да се свържете с „Спешъл Сикюрити Груп“ ООД и писмено на
          следния адрес: гр.Ямбол, ул. Търговска #164 ет.4 офис 11
        </p>
      </div>
    </Layout>
  )
}

export default PolitikaZaPoveritelnost
